


export interface RegisterPersonalDetails {
  ApplicationID?: number;
  RegistrationNo?: string;
  ApplicantName?: string;
  CAPID?: number | null;
  CommunityID?: number | null;
  AdmissionQuota?: number | null;
  DOB?: Date | null;
  Gender?: string;
  MaritalStatus?: string;
  AppPhoto?: string;
  BloodGroupID?: number | null;
  PlaceOfBirth: string;
  MotherTongueID?: number | null;
  NationnalityID?: number | null;
  StudentCategoryID?: number | null;
  ReligionID?: number | null;
  CasteID?: number | null;
  BoardingPoint?: string;
  AadharNo?: string;
  AnnualIncome?: number | null;
  IncomeType?: number | null;
  Email?: string;
  Village?: string;
  Taluk?: string;
  Initial?: string;
  NatureOfResidential?: string;
  DistanceFromCollege?: string;
  HandicapedWeitage?: string;
  Corporation?: string;
  Municipality?: string;
  Panchayat?: string;
  Parish?: string;
  Diocese?: string;
  PeriodofDiscontinuation?: string;
  ReasonofDiscontinuation?: string;
  InstitutionStudiedAfterPassing?: string;
  ConvertedChristian?: string;
  EmployeeRelationShip?: string;
  SecondChoice?: number | null;
  Courseundergone?: number | null;
  DisabilityPercent?: number | null;

  DisabilityID?: number | null;






}





export interface StudentForm {

  ApplicationID: number;
  RegistrationNo: string;
  ApplicantName?: string | null;
  AdmissionQuota?: string | null;
  ExpansionOfIntial?: string | null;
  DOB?: Date | null;
  Gender?: string | null;
  ReligionID?: number | null;
  CasteID?: number | null;
  StudentCategoryID?: number | null;
  NationnalityID?: number | null;
  IDNumber?: string | null;
  PlaceOfBirth?: string | null;
  Guardian?: string | null;
  AnnualIncome?: number | null;
  Parish?: string | null;
  Diocese?: string | null;
  Email?: string | null;
  QualificationID?: number | null;
  QualificationDetails?: string | null;
  Percentage?: number | null;
  FirstChoice?: number | null;
  SecondChoice?: number | null;
  ThirdChoice?: number | null;
  LanguageChoice?: number | null;
  MotherName?: string | null;
  ApplyOn?: Date | null;
  OccupationID?: number | null;
  LastInstitutionID?: number | null;
  UniversityOrBoardID?: number | null;
  ExamRegNo?: string | null;
  NoOfChance?: number | null;
  YearOfPassing?: number | null;
  AppPhoto?: string | null;
  EntranceExamID?: number | null;
  AdmissionByCAP?: string | null;
  AdmitCourse?: number | null;
  AdmitLanguage?: number | null;
  AdmitQuota?: number | null;
  TotalMark?: number | null;
  RankMark?: number | null;
  MotherOccupationID?: number | null;
  BoardingPoint?: string | null;
  NSSWeightage?: string | null;
  NCCWeightage?: string | null;
  SportsWeightage?: string | null;
  DistanceFromCollege?: string | null;
  IsAdmitted?: string | null;
  CAPID?: string | null;
  AdmitCollege?: number | null;
  UserID?: string | null;
  Password?: string | null;
  Status?: string | null;
  StudentID?: number | null;
  StreamID?: number | null;
  AdmissionModal1?: number | null;
  AdmissionModal2?: number | null;
  AdmissionModal3?: number | null;
  MainSystem?: number | null;
  MorG?: number | null;
  ChallanNo?: string | null;
  BankBranch?: string | null;
  SunadySchoolUnit?: string | null;
  IsEligibleBonus?: string | null;
  IsRecommendation?: string | null;
  RecomentedBy?: string | null;
  AdharCardNo?: string | null;
  SubcastID?: number | null;
  MothersOccupation?: string | null;
  FathersOccupation?: string | null;
  LastInstitution?: string | null;
  OtherQualification?: string | null;
  OtherBoard?: string | null;
  MotherIncome?: number | null;
  EmployeeRelationShip?: string | null;
  ConvertedChristian?: string | null;
  AluminiName?: string | null;
  AluminiStatus?: string | null;
  AluminiRelation?: string | null;
  AluminiYear?: string | null;
  ChurchWorkerStatus?: string | null;
  HostalStatus?: string | null;
  SocialWelfareWeitage?: string | null;
  ExtraCurricularActivityWeitage?: string | null;
  SportsWeigtageDetails?: string | null;
  InstitutionStudiedAfterPassing?: string | null;
  ApplicantEmployedDetails?: string | null;
  RecomendedBy?: string | null;
  RecomendedDesignation?: string | null;
  RecomendedPhone?: string | null;
  RecomendationFile?: string | null;
  MaritialStatus?: string | null;
  BloodGroupID?: number | null;
  AdditionalInformation?: string | null;
  OverallCredit?: number | null;
  OverallCGPA?: number | null;
  MaximumCGPA?: number | null;
  BoardofClassXExamination?: number | null;
  ClassXRegisterNumber?: string | null;
  ClassXYearofPassing?: string | null;
  ClassXMarksObtained?: string | null;
  ClassXNameoftheSchool?: string | null;
  HandicapedWeitage?: string | null;
  ExServiceWeitage?: string | null;
  RelationID?: number | null;
  ChallanDate?: Date | null;
  IsManagement?: string | null;
  PreInstitutionTcNo?: string | null;
  PreInstitutionTcDate?: Date | null;
  MotherTongueID?: number | null;
  Village?: string | null;
  Taluk?: string | null;
  DisabilityPercent?: number | null;
  DegreeScheme?: number | null;
  ModeofTransport?: string | null;
  LocalGuardian?: string | null;
  LocalGuardianAddress?: string | null;
  PeriodofDiscontinuation?: number | null;
  ReasonofDiscontinuation?: string | null;
  MonthOfPassing?: string | null;
  PayMode?: string | null;
  TeacherName?: string | null;
  TeacherPhoneNumber?: string | null;
  CommunityID?: number | null;
  Panchayat?: string | null;
  Municipality?: string | null;
  Corporation?: string | null;
  ElectionCardNo?: string | null;
  AppBankName?: string | null;
  AppBankBranch?: string | null;
  AppBankBranchCode?: string | null;
  AppBankMICR?: string | null;
  AppBankIFSC?: string | null;
  AppBankAccountNo?: string | null;
  LocalGuardianOccupation?: string | null;
  LocalGuardianIncome?: number | null;
  ClassXMonthofPassing?: string | null;
  PaymentStatus?: string | null;
  AppSign?: string | null;
  AppParentSign?: string | null;
  AllotmentType?: number | null;
  FatherQualification?: string | null;
  MotherQualification?: string | null;
  AadharNo?: string | null;
  IncomeType?: number | null;
  Initial?: string | null;
  NatureOfResidential?: string | null;

}




export interface EntranceExamMaster {

  EntranceExamID?: number | null;
  EntranceExamName?: string | null;
  CourseCategoryID?: number | null;
  InstitutionID?: number | null;
  SSLC?: string | null;
  PlusTwo?: string | null;
  Degree?: string | null;




}



export interface Setting {
  SettingID: number;
  Initial?: string | null;
  Community?: string | null;
  AdmissionQuota?: string | null;
  POB?: string | null;
  Email?: string | null;
  MobileNo?: string | null;
  Nationality?: string | null;
  Cast?: string | null;
  SubCast?: string | null;
  Parish?: string | null;
  Diocese?: string | null;
  MaritalStatus?: string | null;
  BloodGroup?: string | null;
  Category?: string | null;
  IncomeType?: string | null;
  AnnualIncome?: string | null;
  MotherTongue?: string | null;
  AadharNo?: string | null;
  ApplicationNo?: string | null;
  RegistrationNo?: string | null;
  CapID?: string | null;
  PhysicallyChallenged?: string | null;
  FatherOccupation?: string | null;
  MotherOccupation?: string | null;
  FatherQualification?: string | null;
  MotherQualification?: string | null;
  Relation?: string | null;
  Village?: string | null;
  Taluk?: string | null;
  BoardingPoint?: string | null;
  DistanceFromCollage?: string | null;
  DiscontinuedStudy?: string | null;
  CourseUnderGoneAfterPassing?: string | null;
  Residence?: string | null;
  NatureOfResidential?: string | null;
  HinduByBirth?: string | null;
  ElligibleForStaffQuota?: string | null;
  CommunicationAdd?: string | null;
  PermenantAdd?: string | null;
  ParentAdd?: string | null;
  StudentAdd?: string | null;
  Address?: string | null;
  Province?: string | null;
  District?: string | null;
  PostOffice?: string | null;
  GuardianPhone?: string | null;
  MobileAdd?: string | null;
  EmailAdd?: string | null;
  Phone?: string | null;
  Type?: string | null;
  WithFees?: string | null;
  DocumentUploads?: string | null;
  PlusTwoOrSSLC?: string | null;
}






export interface TbRegApplicantsParentDetails {
  ApplicantID: number;
  FatherName: string;
  FatherPhone: string;
  FatherWANo: string;
  FatherMailID: string;
  FatherOccupation: string;
  FatherQualificationID: number;
  MotherName: string;
  MotherPhone: string;
  MotherWANo: string;
  MotherMailID: string;
  MotherOccupation: string;
  MotherQualificationID: number;
  GuardianName: string;
  GuardianWANo: string;
  GuardianMailID: string;
  GuardianPhone: string;
  GuardianRelationID?: number; // Optional as indicated by int?
}





export interface StudentModel {
  StudentID?: number;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  ProvinceID?: string;
  DistrictID?: string;
  PostOfficeID?: string;
  PinCode?: number;
  Phone?: number;
  Mobile?: number;
  StudentName?: string;
  Initials?: string;
  ExpansionOfInitials?: string;
  RegisterNo?: string;
  RegisterNoSortOrder?: string;
  UniversityRegisterNo?: string;
  DateofBirth?: Date;
  PlaceofBirth?: string;
  Sex?: string;
  MaritalStatus?: string;
  ReligionID?: number;
  StudentCategoryID?: number;
  ResidentCategoryID?: number;
  CasteID?: number;
  mothertongueID?: number;
  NationalityID?: number;
  EnrollmentDate?: Date;
  EnrolledAcademicYearID?: number;
  EnrolledCourseSemesterYearID?: number;
  DistanceFromTheCollege?: string;
  PreInstitutionID?: number;
  PreInstitutionCourse?: string;
  PreInstitutionMedium?: number;
  PreInstitutionTcNo?: string;
  PreInstitutionTcDate?: Date;
  PreInstitutionName?: string;
  Guardian?: string;
  IdentificationMark1?: string;
  IdentificationMark2?: string;
  FoodType?: string;
  Batch?: string;
  BloodGroup?: string;
  Email?: string;
  AccheadID?: number;
  Remarks?: string;
  ApplicationNo?: string;
  SecondLanguageID?: number;
  RunningStatusID?: number;
  CautionDepositAmtStatus?: boolean;
  UniversityRegisterNoSortOrder?: string;
  AdmissionQuotaID?: number;
  AdmissionQuotaNo?: number;
  ImageFileName?: string;
  DOBInWords?: string;
  SubCasteID?: number;
  IDCode?: string;
  ResearchGuide?: string;
  ResearchTopic?: string;
  CAPID?: string;
  MatriculationNo?: string;
  InstitutionID?: null;
  LocalGuradianDetails?: string;
  LocalGuradianPhoneNo?: string;
  IsLateral?: string;
  StudentConcessionID?: null;
  AdharNo?: string;
  IsReAdmitted?: null;
  ReAdmittedDate?: Date;
  ReAdmittedCourseSemesterYearID?: null;
  IsSingleChild?: null;
  IsElectrifiedHouse?: null;
  IsToiletFacility?: null;
  NoOfSiblingsStudiedHere?: null;
  HouseType?: null;
  RecommendedBy?: string;
  OldRegisterNo?: null;
  ClassDescription?: null;
  PhotoPath?: string;
  ClassRollNo?: null;
  EnrolledCourseID?: number;
}


export interface tbAddress {
  ApplicationID: number;
  AddressID: number;
  typeId: number;
  AddressTypeID: number;
  Address1: string;
  Address2?: string | null;
  Address3?: string | null;
  PostOfficeName?: string | null;
  PinCode?: string | null;
  DistrictID?: number | null;
  ProvinceID?: number | null;
  Phone?: string | null;
  Mobile?: string | null;
  Fax?: string | null;
  Email?: string | null;
  ApplicantID?: number | null;

}

export interface BonusMark {
  ApplicantID: number;
  BonusMarkID: number;
  Year?: string | null;
}

export interface College {
  CollegeID: number;
  ApplicationID?: number | null;
  College?: string | null;
  From?: Date | null;
  To?: Date | null;
  RegNo?: string | null;
  TotalMarks?: number | null;
  Marks?: number | null;
  SubjectsTaken?: string | null;
  ClassorGrade?: string | null;
  ExamSystem?: string | null;
}


export interface tbRegisteredApplicants {
  RegApplicantDocDetailID: number;
  ApplicantID: number;
  DOCID: number;
  Doc_No?: string | null;
  Doc_Date?: Date | null;
  RefNo?: string | null;
  Doc_Details?: string | null;
  VerifiedOrNot?: string | null;
  DocStatus?: string | null;
  Remarks?: string | null;
  FilePath?: string | null;
  ApplicationID: number;
  RegistrationNo: string;
  ApplicantName: string;
  CAPID?: number;
  CommunityID?: number;
  AdmissionQuota?: number;
  DOB?: Date;
  Gender: string;
  MaritalStatus: string;
  BloodGroupID?: number;
  PlaceOfBirth: string;
  MotherTongueID?: number;
  NationnalityID?: number;
  StudentCategoryID?: number;
  ReligionID?: number;
  CasteID?: number;
  BoardingPoint: string;
  AadharNo: string;
  LocalGuardian: string;
  MotherName: string;
  RelationID?: number;
  FathersOccupation: string;
  MothersOccupation: string;
  FatherQualification?: number;
  MotherQualification?: number;
  AnnualIncome?: number;
  IncomeType?: number;
  Email: string;
  Village: string;
  Taluk: string;
  Initial: string;
  NatureOfResidential: string;
  DistanceFromCollege: string;
  HandicapedWeitage: string;
  Corporation: string;
  Municipality: string;
  Panchayat: string;
  Parish: string;
  Diocese: string;
  PeriodofDiscontinuation: string;
  ReasonofDiscontinuation: string;
  InstitutionStudiedAfterPassing: string;
  ConvertedChristian: string;
  EmployeeRelationShip: string;
  GuardianCountryID?: number;
  LastInstitution: string;
  TotalMarks?: number;
  Yearofpassing: string;
  MarkPercentage?: number;
  IsPassedLastCourse: string;
  SponsorTypeID?: number;
  SponserType: string;
  StartingSemesterID?: number;
  StartingSemester: string;
  ModeofStudyID?: number;
  ModeofStudy: string;
  EnglishProficiancyID?: number;
  EnglishProficiancy: string;
  EnglishProficiancyScore?: number;
  CourseName: string;
  OtherQualification: string;
  RecomendedBy: string;
  RecomendedDesignation: string;
  RecomendedPhone: string;
  RecommendedByOrganisation: string;
  RecommendedByEmail: string;
  Address1: string; // city
  Address2: string; // P.O.Box
  Address3: string; // Street Name
  Address4: string; // street No
  Address5: string; // Area Name
  Address6: string; // Area No
  CountryID?: number;
  Country: string;
  Phone: string;
  Phoneoffice: string;
  Mobile: string;
  EntranceExamID: number;
  CategoryID: number;
  Status: string;
  PaidDate?: Date;
  PayRefNo: string;
  CountryPhoneCode: string;
  AppPhoto: string;
  Password: string;
  UserID: string;
  InstitutionID: number;
  AcademicYearID: number;
  Token: string;
  InstitutionName: string;
  InstitutionAddress1: string;
  InstitutionAddress2: string;
  InstitutionAddress3: string;
  InstitutionEmblem: string;
  StartingDate?: Date;
  ApplicationDate?: Date;
  JobTitle: string;
  CompanyName: string;
  TranUserID: string;
  ApplyFor: string;
  IsTempSave: string;
  StatusRemakrs: string;
}



export interface OnlineApplicationDocSettings {

  DetailsID: number;
  Mandatory: string;
  Visible: string;
}

export interface afterRegLoginDetails {
  ApplicationID: number;
  AcademicYearID :number;
  InstitutionID : number;
  ApplicantName : string ;
  IsCompleted: string;
  Token: string;
  IsPersonalDetailsCompleted: string;
  IsContactDetailsCompleted: string;
  IsEducationalDetailsCompleted: string;
  IsDocumentUploaded: string;
  InstitutionName: string;
  Address1: string;
  Address2?: string; // Optional
  Address3?: string; // Optional
  DistrictID?: number;
  ProvinceID?: number;
  CountryNationalityID?: number;
  Url?: string;
  SectorID?: number;
  ChapterType?: number;
  // StartDate?: Date; // Uncomment if needed
  Thaluk?: string;
  PostOfficeID?: number;
  PostOfficeDistance?: string;
  TelegraphOffice?: string;
  TelegraphOfficeDistance?: string;
  RailwayStation?: string;
  RailwayStationDistance?: string;
  ManagementAddress?: string;
  SortOrder?: number;
  Remarks?: string;
  University_ExamBoardID?: number;
  Emblem?: string;
  Email?: string;
  PhoneNo?: string;
  LastLoginAcademicYearID?: number;
  Format?: string;
  InstitutionCode?: string;
  UserID?: string;
  District?: string;
  Province?: string;
  CountyNationality?: string;
  ExaminationFee?: number;
  TranNo?: number;
  PaymentStatus: string;

}



export interface RegApplicantSubjectDetails {

  SubjectID?: number;
  SubjectName: string;
  MaxMark: string;
  Mark: string;
}

export interface subjectGroupSettings {

  SubjectGroupSettingsID?: number;
  BoardID: number;
  QualificationID?: number;
  PartID?: number;
  DisplayName?: string;
  Subject?: string;

}

export interface CombinedSubjectDetails {
  Subject: string;
  DisplayName: string;
  SubjectID: string | null;
  MaxMark: string | number;
  MarkObtained: number;
  SubjectGroupSettingsID: number;
  PartID: number;
  SubjectName: string;
  PartName: string;
  MarkorGrade?: string | null;

}


export interface ExamMarkDetail {

  ExamMarkDetailID: number;
  ExamQualifySubjectID: number;
  SubjectID: number;
  MarkorGrade: string | null;
  MaxMark: number;
  MarkObtained: number | null;
  GradeObtained?: string | null;
  ApplicantID: number;
  Credit?: number | null;
  SubjectSettingID?: number | null;
  PartID?: number | null;
  SubjectGroupSettingsID?: number | null;
}




export interface RegApplicantExamMarkDetailforPG {
  ExamMarkDetailID: number | null; // Allow null
  ExamQualifySubjectID: number;
  SubjectID?: number | null;
  SubjectName: string;
  MarkOrGrade?: string | null; // Changed from char to string because TypeScript does not have a char type
  MaxMark?: number | null;
  MarkObtained?: number | null;
  GradeObtained?: string | null;
  Credit?: number | null;
  SubjectSettingID?: number | null;
  PartID?: number | null;
  PartName?: string | null;
  SubjectGroupSettingsID?: number | null;
}



export interface PGSubjectGroupSetting {
  SubjectGroupID: number | null;
  ExamQualifySubjectID: number;
  CategoryID: number;
  PartID: number;
  Description: string;
}




export interface RegApplicantQuota {
  RegApplicantQuotaID?: number | null;
  ApplicantID?: number | null;
  AdmissionQuotaID?: number | null;
  SheduleDate?: Date | null;
  SheduleTime?: string | null;
}




export interface graceform {
  NSSWeightage?: string | null;
  NCCWeightage?: string | null;
  ExServiceWeitage?: string | null;
  SportsWeightage?: string | null;
}


export interface matriculationFormmod {

  BoardofClassXExamination?: number | null;
  ClassXRegisterNumber?: string | null;
  ClassXNameoftheSchool?: string | null;
  ClassXMonthofPassing?: string | null;
  ClassXYearofPassing?: string | null;
}


export interface OnlineEnquiry {
  enquiryID: number;
  name: string;
  mobile: string;
  email: string;
  dob: Date | null;
  enquiryDate: Date | null;
  courseCategoryID: number;
  institutionID: number;
}



export interface tbOtherInstitution {

  OtherInstitutionID: number;
  InstitutionName: string;
  InstitutionType?: number | null;
  Address1: string;
  Address2: string;
  Address3: string;
  PostOfficeID?: number | null;
  DistrictID?: number | null;
  ProvinceID?: number | null;
  Phone: string;
  Fax: string;
  Email: string;
  Url: string;
  AuthorityName: string;
  Remarks: string;

}

export interface tbOtherInstitutionss {

  OtherInstitutionID: number;
  InstitutionName: string;
  InstitutionType?: number | null;
  Address1: string;
  Address2: string;
  Address3: string;
  PostOfficeID?: number | null;
  DistrictID?: number | null;
  ProvinceID?: number | null;
  Phone: string;
  Fax: string;
  Email: string;
  Url: string;
  AuthorityName: string;
  Remarks: string;


}


export interface TbUniversityExamBoard {
  UniversityExamBoardID: number;
  Name: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  PostOfficeID?: number | null;
  DistrictID?: number | null;
  ProvinceID?: number | null;
  TelePhoneNo: string;
  Email: string;
  Url: string;
  AuthorityType: number;
  GovernmentOrStateID?: number | null;
}


export interface UniversityExamBoard {
  University_ExamBoardID: number;
  Name: string;
}


export interface QualificationDetailss {
  DetailsID: number;
  MasterItemID: number;
  Code: number;
  DisplayName: string;
  Description: string;
  ParentDetailsID: number;
  IsDefault: boolean;
  ShortName: string;
  Priority: number;
  InstitutionID: number;
  Remark: string;
}


export interface tbRegisteredApplicantsPersonalDetails {
  ApplicationID?: number;
  RegistrationNo?: string;
  ApplicantName?: string;
  CAPID?: string | null;
  CommunityID?: number | null;
  AdmissionQuota?: number | null;
  DOB?: Date;
  Gender?: string;
  MaritalStatus?: string;
  AppPhoto?: string;
  BloodGroupID?: number | null;
  PlaceOfBirth: string;
  MotherTongueID?: number | null;
  NationnalityID?: number | null;
  StudentCategoryID?: number | null;
  ReligionID?: number | null;
  CasteID?: number | null;
  BoardingPoint?: string;
  AadharNo?: string;
  AnnualIncome?: number | null;
  IncomeType?: number | null;
  Email?: string;
  Village?: string;
  Taluk?: string;
  Initial?: string;
  NatureOfResidential?: string;
  DistanceFromCollege?: string;
  HandicapedWeitage?: string;
  Corporation?: string;
  Municipality?: string;
  Panchayat?: string;
  Parish?: string;
  Diocese?: string;
  PeriodofDiscontinuation?: string;
  CourseUnderGoneAfterPassing?: string;
  ReasonofDiscontinuation?: string;
  InstitutionStudiedAfterPassing?: string;
  ConvertedChristian?: string;
  EmployeeRelationShip?: string;

}


export interface RegApplicantQualificationDetails {
  ApplicationID: number;
  BoardofClassXExamination: number;
  ClassXRegisterNumber: string;
  ClassXYearofPassing: string;
  ClassXNameoftheSchool: string;
  ClassXMarksObtained: string;
  ClassXMonthofPassing: string;
  NCCWeightage: string;
  NSSWeightage: string;
  HandicapedWeitage: string;
  ExServiceWeitage: string;
  SportsWeightage: string;
  UniversityOrBoardID: number;
  OtherQualification: string;
  QualificationID: number;
  LastInstitutionID: number;
  ExamRegNo: string;
  YearOfPassing: number;
  StreamID: number;
  NoOfChance: number;
  Percentage: number;
  TotalMark: number;
  RankMark: number;
  FirstChoice: number;
  SecondChoice: number;
  ThirdChoice: number;
  LanguageChoice: number;
  DegreeTypeID: number;
  AcademicPathwayID: number;
  MainSystem?: number;
  MorG?: string | null;
  NewInstitution: string | null;
  RegApplicantExamMarkDetail: CombinedSubjectDetails[];
  RegApplicantBonusDetails: RegApplicantBonusDetails[];
}

export interface RegApplicantBonusDetails {

  BonusMarkID: number;
  Year: string;
  IsChecked: Boolean;

}


export interface BonusDetails {
  BonusMarkID: number;
  Header: string;
  SubHeader: string;
  BonusItemID: number;
  BonusMark: number;
  AwardedID: number;
  Result: string;
  isChecked: boolean;
  year: string;
}

export interface RegApplicantExamMarkDetail {
  ExamMarkDetailID: number;
  ExamQualifySubjectID: number;
  SubjectID?: number | null;
  MarkorGrade?: string | null; // 'char' in C# is typically represented as a single character string in TypeScript
  MaxMark?: number | null;
  MarkObtained?: number | null;
  GradeObtained: string;
  Credit?: number | null;
  SubjectSettingID?: number | null;
  PartID?: number | null;
  SubjectGroupSettingsID?: number | null;
}


export interface RegApplicantBonusMarkDetail {
  BonusCategoryID: number;
  DisplayName: string;
  Category: string;
  BonusMarkID?: number | null;
  Priority?: number | null; // Int16 in C# is represented as a number in TypeScript
  ApplicantBonusMarkID?: number | null;
  Year: string;
  RegApplicantBonusMark: RegApplicantBonusMarkDetail[]; // Array of RegApplicantBonusMarkDetail
}




export interface OnlineApplicationPaymentModel {
  TranNo?: number | null;  // Optional integer
  ReferanceDate: string;   // Required string
  ReferanceNumber: string; // Required string
  AcknowledgementNumber: string; // Required string
  AmountPaid?: number | null; // Optional decimal
  PaymentDescription: string; // Required string
  PaymentStatus: string; // Required string
  IsConfirmed: string;   // Required string
  FeeStatus: string;     // Required string
  CanCancel: boolean;    // Required boolean
  ResponseStatusCode : string;
}



export interface EntranceExamBonusDetails {
  BonusMarkID: number;
  Header: string;
  SubHeader: string;
  BonusItemID: number;
  BonusMark: number;
  AwardedID: number;
  Result: string;
  isChecked: boolean;
  year: string
}


export interface BoardingPointMaster {

  BoardingPointID : number;
  Place : string;

}



export interface TbCourse {
  CourseID: number;
  CourseName: string;
  CourseShortName: string;
  Duration: number;
  TotalSeats: number;
  Credits: number;
  DepartmentID: number;
  CourseStreamCategoryID: number;
  SectorID: number;
  SanctionedDate?: Date | null;
  StartingDate?: Date | null;
  SemesterYearStatus: number;
  SemesterDuration: number;
  InstructionMediumID: number;
  RunningStatus: number;
  DiscontinuedDate?: Date | null;
  Remarks: string;
  Priority: number;
  AccHeadID: number;
  Examconducted: number;
  OnlineApplicationCutOfPercentage: number;

  CourseCategory: string;
  MainSubject: string;

  InstitutionID: number;
  LoginUserID: number;
  DepartmentName: string;
  CourseStreamName: string;
  POCnt: number;
  PSOCnt: number;
  SYCnt: number;

  CourseSyllabusID: number;
  SyllabusTitle: string;
  SyllabusFileName: string;
}



export interface FieldBox {
  name: string;
  enabled: boolean;
  mandatory: boolean;
  title?: string;
}

export interface FieldsBox {
  detailsId: number; // Corresponds to `DetailsID`
  name: string;
  enabled: boolean;
  mandatory: boolean;
}



export interface OnlineApplicationSettings {
  SettingID: number;
  [key: string]: any;
  Initial?: string;
  Community?: string;
  AdmissionQuota?: string;
  POB?: string;
  Email?: string;
  MobileNo?: string;
  Nationality?: string;
  Cast?: string;
  SubCast?: string;
  Parish?: string;
  Diocese?: string;
  MaritalStatus?: string;
  BloodGroup?: string;
  Category?: string;
  IncomeType?: string;
  AnnualIncome?: string;
  GuardianRelationID?: number;
  MotherTongue?: string;
  AadharNo?: string;
  ApplicationNo?: string;
  RegistrationNo?: string;
  CapID?: string;
  PhysicallyChallenged?: string;
  FatherOccupation?: string;
  MotherOccupation?: string;
  FatherQualificationID?: string;
  MotherQualificationID?: string;
  FatherQualification?: string;
  MotherQualification?: string;
  Relation?: string;
  Village?: string;
  Taluk?: string;
  BoardingPoint?: string;
  DistanceFromCollage?: string;
  DiscontinuedStudy?: string;
  CourseUnderGoneAfterPassing?: string;
  Residence?: string;
  NatureOfResidential?: string;
  HinduByBirth?: string;
  ElligibleForStaffQuota?: string;
  CommunicationAdd?: string;
  PermenantAdd?: string;
  ParentAdd?: string;
  StudentAdd?: string;
  Address?: string;
  Province?: string;
  District?: string;
  PostOffice?: string;
  GuardianPhone?: string;
  MobileAdd?: string;
  EmailAdd?: string;
  Phone?: string;
  Type: string;
  WithFees?: string;
  DocumentUploads?: string;
  PlusTwoOrSSLC?: string;
  FatherName?: string;
  FatherPhone?: string;
  FatherWANo?: string;
  FatherMailID?: string;
  MotherName?: string;
  MotherPhone?: string;
  MotherWANo?: string;
  MotherMailID?: string;
  GuardianName?: string;
  GuardianWANo?: string;
  GuardianMailID?: string;


  LanguageChoice?: string;
  FirstChoice?: string;
  SecondChoice?: string;
  ThirdChoice?: string;
  DegreeTypeID?: string;
  AcademicPathwayID?: string;
  BonusMarkID?: string;

  BoardofClassXExamination?: string;
  ClassXRegisterNumber?: string;

  ClassXNameoftheSchool?: string;

  ClassXMonthofPassing?: string;

  ClassXYearofPassing?: string;





}






export interface TransactionModel {
  TranNo?: number;
  TranDate?: Date;
  InstitutionID?: number;
  AcademicYearID?: number;
  InstallmentID: string;
  SemesterYearStudentID: string;
  ParentID?: number;
  AmountPaid?: number;
  FeeDue?: number;
  BankCharge?: number;
  BankChargeVat?: number;
  Fine?: number;
  MerchantID: string;
  SubscriberID: string;
  TrxReferenceNo: string;
  TrxReferenceNoEncrypt: string;
  Mobile: string;
  Email: string;
  RequestInfo1?: string;
  RequestInfo2?: string;
  RequestInfo3?: string;
  RequestInfo4?: string;
  RequestInfo5?: string;
  RequestInfo6?: string;
  RequestInfo7?: string;
  RequestInfo8?: string;
  RequestInfo9?: string;
  RequestInfo10?: string;
  ResponseInfo1?: string;
  ResponseInfo2?: string;
  ResponseInfo3?: string;
  ResponseInfo4?: string;
  ResponseInfo5?: string;
  ResponseInfo6?: string ;
  ResponseInfo7?: string;
  ResponseInfo8?: string;
  ResponseInfo9?: string;
  ResponseInfo10?: string;
  ResponseInfo11?: string;
  ResponseInfo12?: string;
  ResponseInfo13?: string;
  ResponseInfo14?: string;
  ResponseInfo15?: string;
  ResponseInfo16?: string;
  ResponseInfo17?: string;
  ResponseInfo18?: string;
  ResponseInfo19?: string;
  ResponseInfo20?: string;
  ResponseInfo21?: string;
  ResponseInfo22?: string;
  ResponseInfo23?: string;
  ResponseInfo24?: string;
  ResponseInfo25?: string;
  ResponseInfo26?: string;
  ResponseInfo27?: string;
  ResponseInfo28?: string;
  ResponseInfo29?: string;
  ResponseInfo30?: string;
  FeeDetails?: string;
  PaymentDetails?: string;
  TrackList?: string;
  ResponseStatusCode?: string;
  ResponseStatusDescription?: string;
  CardType?: string;
  CardId?: number;
  ApplicationID?: number;
}



export interface RegApplicantDocumentDetails {
  RegApplicantDocDetailID: number;
  ApplicantID: number;
  DOCID: number;
  Doc_No: string;
  Doc_Date: Date | null;
  RefNo: string;
  Doc_Details: string;
  VerifiedOrNot: string;
  DocStatus: string;
  Remarks: string;
  FilePath: string;
}
